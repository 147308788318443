<div class="signup-stepper-indicator" *ngIf="!isUserLoggingIn">
  <ng-container
    *ngTemplateOutlet="(currentUserStep$ | async) ? finishedLoadingTemplate : loadingTemplate"
  ></ng-container>
  <ng-template #finishedLoadingTemplate>
    <div
      *ngFor="let stepIndicator of stepsIndicators; let idx = index"
      class="signup-stepper-indicator__step{{ stepIndicator.isFilled ? '--filled' : '' }}"
    >
      <div
        *ngIf="idx !== 0"
        class="signup-stepper-indicator__step{{ stepIndicator.isFilled ? '--filled' : '' }}__line"
      >
        <div
          class="signup-stepper-indicator__step{{
            stepIndicator.isFilled ? '--filled' : ''
          }}__line__indicator"
        ></div>
      </div>
      <div
        class="signup-stepper-indicator__step{{ stepIndicator.isFilled ? '--filled' : '' }}__circle"
      ></div>
    </div>
  </ng-template>
  <ng-template #loadingTemplate></ng-template>
</div>
