import { AsyncPipe, NgFor, NgIf, NgTemplateOutlet } from '@angular/common';
import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  Input,
  OnDestroy,
  OnInit,
} from '@angular/core';
import { Subject, takeUntil } from 'rxjs';
import { CurrentUserStep, PossibleIndicatorSteps } from './interfaces';

/**
 * What is this shared component, why do we need it and how do we use it?
 *
 * What is this component?
 *
 * Well, some parts of our application actually need a visual cue on the user progress, and that is what this
 * component does. So, to reduce code duplication for such familiar requirements, we simply get to use this
 * component in all those places.
 *
 * Why do we need it?
 *
 * We need it for :
 *
 * 1. Visual cues os user steps.
 * 2. Reduce amount of code base, making it smaller, robust and extensible.
 * 3. Future proofing, in case we need similar functionality in other parts of the application, we simple use
 * this component, as explained below.
 *
 * How do we use it?
 *
 * Well, this being a smart component, which ideally is a dumb component with respect to it's callers, then it
 * will work solely on inputs. It will accept two main inputs:
 *
 * 1. currentUserStep$ -> This is an Observable which will be the next user step, which is actually part of the
 * received input #2 below (allowedUserSteps)
 *
 * 2. allowedUserSteps -> This is a list of the allowed user steps. Different parts of the application can have
 * N number of steps, and these are those steps which we pass in.
 *
 *
 * IMPORTANT:
 *
 * UNLESS ABSOLUTELY NECESSARY, CAUTION SHOULD BE TAKEN WHEN UPDATING ANY LOGIC IN THIS COMPONENT, BECAUSE AS
 * THE NAME SUGGESTED, IT IS SHARED. THAT MEANS THAT ATTEMPTS TO MODIFY IT TO FIX AN EXTERNAL CALLER OF THIS
 * COMPONENT MAY AND WILL BREAK THE EXPECTED BEHAVIOR IN OTHER CALLERS OF THIS COMPONENT.
 */

interface StepIndicatorMeta {
  index: number;
  isFilled: boolean;
  userStep: CurrentUserStep;
}

@Component({
  selector: 'app-shared-stepper-indicator',
  templateUrl: './shared-stepper-indicator.component.html',
  styleUrls: ['./shared-stepper-indicator.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgTemplateOutlet, NgFor, NgIf, AsyncPipe],
})
export class SharedStepperIndicatorComponent implements OnInit, OnDestroy {
  @Input() public currentUserStep$: Subject<CurrentUserStep> = new Subject<CurrentUserStep>();

  @Input() allowedUserSteps: Array<string>;

  @Input() isUserLoggingIn: boolean;

  public stepsIndicators: Array<StepIndicatorMeta> = [];

  private _onDestroy$: Subject<boolean> = new Subject<boolean>();

  constructor(private _changeDetectorRef: ChangeDetectorRef) {}

  ngOnInit(): void {
    this._initializeSteps();
  }

  ngOnDestroy(): void {
    this._onDestroy$.next(true);
    this._onDestroy$.complete();
  }

  /**
   * Since this component is now shareable, we need to make it more extensible to other possible
   * steps later on.
   */
  private _initializeSteps(): void {
    const stepsIndicators: Array<StepIndicatorMeta> = [];
    Object.values(PossibleIndicatorSteps)
      .filter((value) => this.allowedUserSteps.indexOf(value.toString()) > -1)
      .filter((value) => isNaN(Number(value)))
      .forEach((value: CurrentUserStep, index) =>
        stepsIndicators.push({
          index,
          isFilled: false,
          userStep: value,
        }),
      );
    this.stepsIndicators = stepsIndicators;
    this._fillPassedSteps();
  }

  private _fillPassedSteps(): void {
    this.currentUserStep$.pipe(takeUntil(this._onDestroy$)).subscribe({
      next: (currentUserStep) => {
        const currentUserStepIndexInScreens = this.stepsIndicators.findIndex(
          (step) => step.userStep === currentUserStep,
        );
        if (currentUserStepIndexInScreens > -1) {
          this.stepsIndicators.forEach((stepIndicator) => {
            if (stepIndicator.index <= currentUserStepIndexInScreens) {
              stepIndicator.isFilled = true;
            }
          });
        }
        this._changeDetectorRef.detectChanges();
      },
    });
  }
}
